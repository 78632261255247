<template>
  <div class="container-fluid">
    <div class="row" v-if="loading">
      <div class="col-12">
        <octo-loader/>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-3 col-md-6" v-for="(card, index) in statsCards" :key="`card-${index}`">
        <stats-card
          class="shadow"
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "DashboardStudentCards",
  components: {OctoLoader, StatsCard},
  data() {
    return {
      data: null,
      loading: false
    }
  },
  computed: {
    statsCards() {
      return [
        {
          title: String(this.data?.studentsInCourse),
          subTitle: this.$t('didactics.students'),
          type: 'primary',
          icon: 'users',
          footer: this.$t('didactics.students_waiting_for_courses')
        },
        {
          title: String(this.data?.studentsInCertificate),
          subTitle: this.$t('didactics.students'),
          type: 'warning',
          icon: 'users',
          footer: this.$t('didactics.students_waiting_for_certificates')
        },
        {
          title: String(this.data?.studentsWithoutFirstContact),
          subTitle: this.$t('didactics.students'),
          type: 'success',
          icon: 'users',
          footer: this.$t('didactics.students_without_first_contact')
        },
        {
          title: String(this.data?.studentsWithoutSecondContact),
          subTitle: this.$t('didactics.students'),
          type: 'info',
          icon: 'users',
          footer: this.$t('didactics.students_without_second_contact')
        }
      ];
    }
  },
  mounted() {
    this.loading = true;
    this.data = [];

    this.$api.get(endpoints.DASHBOARD_STUDENTS_STATS)
      .then((resp) => {
        this.data = resp?.data || [];
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>

</style>
