<template>
  <div class="row">
    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category mb-0 text-uppercase">{{ $t('didactics.didactics') }}</h5>
          </div>
        </template>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <dashboard-student-cards/>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <title-section-component :label="$t('common.on_boarding_area')"/>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <base-chart
                :title="$t('chart.student_vs_course')"
                :height="350"
                :endpoint="endPoints.CHARTS.studentVsCourse"
              />
            </div>
            <div class="col col-md-6">
              <base-chart
                :title="$t('chart.student_vs_classroom_pending')"
                :height="350"
                :endpoint="endPoints.CHARTS.studentVsClassroomPending"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <title-section-component :label="$t('common.on_going_area')"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-chart
                :title="$t('chart.student_attending_vs_course')"
                :height="350"
                :endpoint="endPoints.CHARTS.studentAttendingVsCourse"
              />
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import BaseChart from "@/components/Charts/BaseChart";
import {endpoints} from "@/routes/endpoints";
import OctoChartContainer from "@/components/octo-chart-container/OctoChartContainer";
import DashboardStudentCards from "@/pages/Didactics/components/DashboardStudentCards";
import TitleSectionComponent from "@/components/TitleSectionComponent";
import DidacticsDashboardViewHelper from "@/components/Helpers/DidacticsDashboardViewHelper";
import {mapMutations} from "vuex";

export default {
  name: "DashboardView",
  components: {TitleSectionComponent, DashboardStudentCards, OctoChartContainer, BaseChart},
  data() {
    return {
      endPoints: endpoints,
      helperComponent: DidacticsDashboardViewHelper
    }
  },
  mounted() {
    this.setHelper(this.helperComponent);
  },
  methods: {
    ...mapMutations({
      setHelper: 'common/setHelper',
    }),
  },
  beforeDestroy() {
    this.setHelper();
  }
}
</script>

<style scoped>

</style>
