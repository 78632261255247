<template>
  <div>
    <p class="text-uppercase">{{ $t('common.on_boarding_area') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('chart.student_vs_course')">
        <div>
          Distribuzione degli studenti divisi per corsi che sono ancora in attesa e non iscritti a un'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('chart.student_vs_classroom_pending')">
        <div>
          Distribuzione degli studenti divisi per aule che sono iscritti a un'aula in uno dei seguenti stati:
          <label-theme-component>
            {{ $t('didactics.classroom_status.' + classroomStatuses.pending.value) }},
            {{ $t('didactics.classroom_status.' + classroomStatuses.scheduled.value) }} o
            {{ $t('didactics.classroom_status.' + classroomStatuses.ready_to_go.value) }}.
          </label-theme-component>
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase">{{ $t('common.on_going_area') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('chart.student_attending_vs_course')">
        <div>
          Distribuzione degli studenti divisi per corsi che stanno frequentando un corso in un aula.
        </div>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>

import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";

export default {
  name: "DidacticsDashboardViewHelper",
  components: {LabelThemeComponent, CollapseItem, Collapse},
  data() {
    return {
      classroomStatuses: classroomStatuses
    }
  }
}

import LabelThemeComponent from "@/components/LabelThemeComponent";
</script>

<style scoped>

</style>
